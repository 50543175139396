<template>
  <div class="_btnBox">
    <v-btn-toggle v-model="toggle" variant="outlined" divided>
      <v-btn @click="clickBtn(0.1)">1일</v-btn>
      <v-btn @click="clickBtn(0.7)">1주</v-btn>
      <v-btn @click="clickBtn(1)">1개월</v-btn>
      <v-btn @click="clickBtn(3)">3개월</v-btn>
      <v-btn @click="clickBtn(6)">6개월</v-btn>
      <v-btn @click="clickBtn(12)">1년</v-btn>
    </v-btn-toggle>
  </div>
</template>
<script>
export default {
  props: {
    selectedBtn: {
      type: Number,
      default: -1,
    },
  },
  data() {
    return {
      toggle: this.selectedBtn,
    };
  },
  methods: {
    clickBtn(e) {
      console.log("e", e);
      this.$emit("clickBtn", e);
    },
  },
};
</script>
<style lang="scss">
._btnBox {
  margin-right: 15px;
  .v-btn {
    height: 50px !important;
  }
}
</style>