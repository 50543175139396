<template>
  <div class="_layout">
    <DateMonthRadioBtn :selectedBtn="2" @clickBtn="clickBtn" />
    <DateRangeComp
      propsStartDateLabel="Start Date"
      propsEndDateLabel="End Date"
      :propsStartDate="startDate"
      :propsEndDate="endDate"
      :propsDisabled="true"
      returnType="string"
      @changeDate="changeDateRange"
    />
  </div>
</template>

<script>
import DateRangeComp from "@/components/commonV2/ui/DateRangeComp.vue";
import DateMonthRadioBtn from "@/components/commonV2/ui/DateMonthRadioBtn.vue";
export default {
  components: {
    DateRangeComp,
    DateMonthRadioBtn,
  },
  props: {
    // 날짜방식변경(R:과거 ~ 오늘)
    periodOption: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      startDate: "",
      endDate: "",
    };
  },
  mounted() {
    this.clickBtn(1);
  },
  methods: {
    changeDateRange(startDate, endDate) {
      console.log("", startDate, endDate);
      this.startDate = startDate;
      this.endDate = endDate;
      this.finallyEmit();
    },
    clickBtn(e) {
      let date = this.changeDateFromDayToFuture(e);
      this.startDate = date[0].format("YYYY-MM-DD");
      this.endDate = date[1].format("YYYY-MM-DD");
      this.finallyEmit();
    },
    finallyEmit() {
      // console.log("date", this.startDate, this.endDate);
      this.$emit("complete", this.startDate, this.endDate);
    },
    changeDateFromDayToFuture(e) {
      console.log('changeDateFromDayToFuture e', (e*10));
      let today = this.$moment();
      let targetDay = this.$moment();
      if (this.periodOption === "R") {
        today = targetDay.clone().add(e * -1, "months");
        targetDay = this.$moment();
      } else {
        today = this.$moment();
        targetDay = targetDay.clone().add(e, "months");
      }
      // e === 0.1 -> 1일
      // e === 0.7 -> 1주
      if(e < 1) {
        if (this.periodOption === "R") {
          today = targetDay.clone().add(((e*10) * -1) + 1, "day");
          targetDay = this.$moment();
        } else {
          today = this.$moment();
          targetDay = targetDay.clone().add((e*10), "day");
        }
      }
      return [today, targetDay];
    },
  },
};
</script>

<style lang="scss">
._layout {
  display: flex;
  height: 60px !important;
}
</style>