<template>
  <v-dialog v-model="visible" max-width="1000px">
    <v-card class="editorPadding">
      <Header @close="close" />

      <v-card-title> 
        [{{ item && $helper.definedStringI18($helper.makeStringToArray(item.hospitalName, "@@"), 'kr') }} 
        - {{ item && item.patientName }}환자]
        수납내용 수정 로그 </v-card-title>
      <v-card-text>
        <DataTable
          ref="DataTable"
          :tableKey="'rowSeq'"
          :perPage="10000"
          :footerHide="true"
          :singleSelect="true"
          :showSelect="false"
          :columns="tableColumns"
          :inputData="logData"
        />
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import Header from "@/components/commonV2/Dialog/comp/Header.vue";
import DataTable from "@/components/commonV2/table/DataTable.vue";
const formatService = require("@/utils/format.js");

export default {
  components: {
    Header,
    DataTable
  },
  data() {
    return {
      visible: false,
      logData: null,
      item: null,
      tableColumns: formatService.paidLogTableColumns(),
    };
  },
  methods: {
    showModal(item) {
      const $h = this.$helper;
      this.visible = true;
      this.item = item;
      let logData = item.paidLogs;
      if (0 < logData.length) {
        logData = logData.map((item, index) => ({
          ...item,
          regDateLabel: $h.changeDateToStringForDp(item.regDate, true),
          paidValueLabel: $h.commaNum(item.paidValue),
          chargeLabel: $h.commaNum(item.charge),
        }));
      }
      this.logData = logData;
    },
    close() {
      this.visible = false;
    },
    ...mapActions(["saveDataQuery"]),
  },
};
</script>