<template>
  <v-row>
    <v-col>
      <v-card-text class="card-title ma-0">
        정산
        <!-- 도움말 -->
        <HelpMsg :msgType="helpMsg" />
      </v-card-text>
    </v-col>
    <v-col cols="12" md="12">
      <base-card>
      <SelectBoxClinic @updateSelect="updateSelectClinic" />
      <DateRangeCompWithButton periodOption="R" @complete="changeDateRange" />
      </base-card>
    </v-col>
    <v-col cols="12" lg="3" md="6">
      <price-card
        price-title="선택기간 총 매출"
        :amount="`￦ ${$helper.commaNum(totalSale)}`"
        card-icon="mdi-call-made"
        footer-text="선택기간(병원) 매출"
        chip-color="success lighten-5"
        chip-text="+33.57%"
        chip-text-color="success--text"
      />
    </v-col>
    <v-col cols="12" lg="3" md="6">
      <price-card
        price-title="선택기간 총 수수료"
        :amount="`￦ ${$helper.commaNum(sumCharge)}`"
        card-icon="mdi-call-received"
        color-icon="danger--text"
        chip-color="danger lighten-5"
        footer-text="선택기간(병원) 수수료"
        chip-text="+13.57%"
        chip-text-color="danger--text"
      />
    </v-col>
    <v-col cols="12" lg="3" md="6">
      
    </v-col>
    <!-- Todo 엑셀다운로드 처리 -->
    <v-col cols="12" lg="3" md="6">
      <!-- <price-card
        price-title="Total Expense"
        amount="$62000"
        card-icon="mdi-call-received"
        color-icon="warning--text"
        chip-color="warning lighten-5"
        footer-text="Profit in this Period"
        chip-text="+13.57%"
        chip-text-color="warning--text"
      /> -->
    </v-col>
    <v-col cols="12" md="12">
      <base-card>
        <div class="d-flex align-center justify-space-between pr-3">
        </div>
        <v-card-text>
          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">병원명</th>
                  <th class="text-left">환자명</th>
                  <th class="text-left">지불한내용</th>
                  <th class="text-left">지불한금액</th>
                  <th class="text-left">수수료</th>
                  <th class="text-center">수술/시술/이벤트</th>
                  <th class="text-center">Actions</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in paidData" :key="item.name">
                  <td class="text-no-wrap">
                    <div class="d-flex align-center">
                      <p class="ma-0">
                        {{ item.hospitalNameKr }}
                      </p>
                    </div>
                  </td>
                  <td class="text-no-wrap">
                    <v-avatar size="36px" class="mr-2">
                      <v-img :src="item.cardImg" alt />
                    </v-avatar>
                    {{ item.patientName }}
                  </td>
                  <td class="text-no-wrap">
                    {{ item.productNameKr }}
                  </td>
                  <td>
                    <h6 class="text-success">￦ {{ $helper.commaNum(item.paidValue) }}</h6>
                  </td>
                  <td class="text-no-wrap">
                    <h6 class="text-warning">￦ {{ $helper.commaNum(item.charge) }}</h6>
                  </td>
                  <td class="text-center">
                    <h6 :class="item.chargeType === '이벤트'? 'text-danger':''">{{ item.chargeType }}</h6>
                  </td>
                  <!-- <td>
                    <div class="d-flex align-center">
                      <v-sheet
                        width="20"
                        height="20"
                        class="success lighten-4 rounded-circle d-flex align-center justify-center mr-2"
                      >
                        <v-icon class="success--text caption">mdi-check</v-icon>
                      </v-sheet>
                      <span>{{ item.status }}</span>
                    </div>
                  </td> -->
                  <td class="text-center">
                    <v-menu left bottom>
                      <template v-slot:activator="{ on }">
                        <v-btn icon v-on="on">
                          <v-icon class="text-primary"
                            >mdi-dots-horizontal</v-icon
                          >
                        </v-btn>
                      </template>

                      <v-list>
                        <v-list-item @click="clickEdit(item)">
                          <v-list-item-title>수정/삭제</v-list-item-title>
                        </v-list-item>
                        <!-- <v-list-item @click="() => {}">
                          <v-list-item-title>삭제</v-list-item-title>
                        </v-list-item> -->
                        <v-list-item @click="clickLogView(item)">
                          <v-list-item-title>로그확인</v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card-text>
      </base-card>
    </v-col>

    <!-- 수정 / 삭제 -->
    <DialogPaidHistoryEditor 
      ref="refDialogPaidHistoryEditor" 
      @updateDeleteComplete="getData"/>

    <!-- 로그 -->
    <DialogPaidHistoryLogView 
      ref="refDialogPaidHistoryLogView" />
  </v-row>
</template>
<script>
import { mapActions } from "vuex";
import HelpMsg from "@/components/commonV2/helpMsg/HelpMsg.vue";
import SelectBoxClinic from "@/components/commonV2/ui/SelectBoxClinic.vue";
import DateRangeCompWithButton from "@/components/commonV2/ui/DateRangeCompWithButton.vue";
import TestCard from "@/components/card/TestCard";
import PriceCard from "@/components/card/PriceCard";
import DialogPaidHistoryEditor from "./DialogPaidHistoryEditor.vue";
import DialogPaidHistoryLogView from "./DialogPaidHistoryLogView.vue";
import { gradientLineChart, salesByCountries, visitorsAnalytic } from "@/data/sales2";
const modelService = require("@/utils/dataModel");
export default {
  name: "Sales",
  metaInfo: {
    // title will be injected into parent titleTemplate
    title: "Sales",
  },
  components: {
    DialogPaidHistoryEditor,
    DialogPaidHistoryLogView,
    HelpMsg,
    SelectBoxClinic,
    DateRangeCompWithButton,
    "test-card": TestCard,
    "price-card": PriceCard,
  },
  data() {
    return {
      helpMsg: "도움말",
      paidData: null,
      reqData: modelService.reqDataModel(),
      defaultDate: [this.$moment().subtract(1, "month"), this.$moment()],
      totalSale: 0,
      sumCharge: 0,
    };
  },
  created() {
    const $h = this.$helper;
    this.reqData.fromDay = $h.changeDateToString(this.defaultDate[0], false);
    this.reqData.toDay = $h.changeDateToString(this.defaultDate[1], false);
    this.getData();
  },
  methods: {
    /**** 클리닉 선택 ***/
    updateSelectClinic(e) {
      this.reqData.hospital_id = e === "" ? 0 : e;
      this.getData();
    },
    /**** 기간 선택 ***/
    changeDateRange(fromDay, toDay) {
      console.log("changeDateRange", fromDay, toDay);
      this.reqData.fromDay = fromDay;
      this.reqData.toDay = toDay;
      this.getData();
    },
    // 수납 한 내용
    getData() {
      const $h = this.$helper;
      this.paidData = [];
      this.reqData.endpoint = 'allPaymentInfo';
      this.getDataQuery(this.reqData).then((resP) => {
        if(resP === undefined) return;
        let res = resP.data;
        if (0 < res.length) {
          res = res.map((item, index) => ({
            ...item,
            no: index + 1,
            hospitalNameKr: $h.definedStringI18($h.makeStringToArray(item.hospitalName, "@@"), 'kr'),
            regDateLabel: $h.changeDateToStringForDp(item.regDate, true),
            dpDateLabel: $h.changeDateToForUpdateDp(item.regDate, item.updDate),
            cardImg: $h.makeUserAvatarImg(
              item.userPhotoImg,
              $h.isPhotoChecker(item.userPhotoImg),
              item.gender
            ),
          }));
        }
        this.paidData = res;
        this.calSumCharge();
        this.calSumTotalSale();
      })
    },
    calSumTotalSale() {
      this.totalSale = 0;
      this.paidData.forEach(element => {
        // console.log('element', element.paidValue);
        this.totalSale = this.totalSale + Number(element.paidValue);
      });
    },
    calSumCharge() {
      this.sumCharge = 0;
      this.paidData.forEach(element => {
        // console.log('element', element.charge);
        this.sumCharge = this.sumCharge + Number(element.charge);
      });
    },
    clickEdit(item) {
      this.$refs.refDialogPaidHistoryEditor.showModal(item);
    },
    clickLogView(item) {
      this.$refs.refDialogPaidHistoryLogView.showModal(item);
    },
    ...mapActions(["getDataQuery", "saveDataQuery"]),
  },
};
</script>