<template>
  <div class="_dateBox">
    <DatePickerWithInputText
      ref="refStartDate"
      :label="propsStartDateLabel"
      :value="startDate"
      :propsDisabled="propsDisabled"
      @onChangeDate="onChangeStartDate"
    />
    <span class="periodBox">~</span>
    <DatePickerWithInputText
      ref="refEndDate"
      :label="propsEndDateLabel"
      :value="endDate"
      :propsDisabled="propsDisabled"
      @onChangeDate="onChangeEndDate"
    />
  </div>
</template>

<script>
import DatePickerWithInputText from "@/components/commonV2/ui/DatePickerWithInputText.vue";
const format = "MM-DD-YYYY";
export default {
  components: {
    DatePickerWithInputText,
  },
  props: {
    propsStartDate: {
      type: String,
      default: format,
    },
    propsEndDate: {
      type: String,
      default: format,
    },
    propsStartDateLabel: {
      type: String,
      default: "",
    },
    propsEndDateLabel: {
      type: String,
      default: "",
    },
    propsDisabled: {
      type: Boolean,
      default: false,
    },
    dateFormat: {
      type: String,
      default: format,
    },
    returnType: {
      type: String,
      default: "date",
    },
  },
  watch: {
    propsStartDate(val) {
      this.startDate = val;
    },
    propsEndDate(val) {
      this.endDate = val;
    },
  },
  data() {
    return {
      startDate: this.propsStartDate,
      endDate: this.propsEndDate,
    };
  },
  methods: {
    onChangeStartDate(e) {
      this.startDate = e;
      this.changeDate();
    },
    onChangeEndDate(e) {
      this.endDate = e;
      this.changeDate();
    },
    changeDate() {
      // 뒷 날짜 체크
      let startDate = this.$helper.changeStringToDate(this.startDate);
      let endDate = this.$helper.changeStringToDate(this.endDate);
      // console.log("", startDate, endDate);
      // return;
      const diffDay = this.$moment.duration(endDate.diff(startDate)).asDays();
      // console.log("", this.startDate, this.endDate, diffDay);
      // return;
      // 기간중 뒷날짜 확인용;
      if (diffDay < 0) {
        const msg = "The end date cannot be later than the start date.";
        this.$helper.showTostMessage("", msg, "error");
        this.endDate = "";
        this.$refs.refEndDate._resSetDate();
        return;
      }
      console.log("후", this.startDate, this.endDate, diffDay);
      if (this.returnType === "string") {
        startDate = this.$helper.changeDateToString(startDate);
        endDate = this.$helper.changeDateToString(endDate);
      }
      this.$emit("changeDate", startDate, endDate);
    },
  },
};
</script>

<style lang="scss">
._dateBox {
  display: flex;
  min-width: 370px;
}
.periodBox {
  padding: 20px;
}
</style>