<template>
  <v-dialog v-model="visible" max-width="500px">
    <v-card class="editorPadding">
      <Header @close="close" />

      <v-card-title> 
        [{{ item && $helper.definedStringI18($helper.makeStringToArray(item.hospitalName, "@@"), 'kr') }} 
        - {{ item && item.patientName }}환자]
        수납내용 수정 </v-card-title>
      <v-card-text>
        <paymentCard
          :bookingData="item"
          :item="item"
          :title="item && item.productNameKr"
          :sub-title="item && item.paidValueLbl"
          :regDateLabel="item && item.dpDateLbl"
          @updateDeleteComplete="$emit('updateDeleteComplete');close()" 
        />
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import Header from "@/components/commonV2/Dialog/comp/Header.vue";
import paymentCard from "@/components/commonV2/Dialog/payment/paymentCard.vue";

export default {
  components: {
    Header,
    paymentCard
  },
  props: {
    bookingData: {
      type: [Object, Array]
    },
  },
  data() {
    return {
      visible: false,
      item: null
    };
  },
  methods: {
    showModal(item) {
      this.visible = true;
      this.item = item;
      console.log('item', item);
    },
    close() {
      this.visible = false;
    },
    ...mapActions(["saveDataQuery"]),
  },
};
</script>